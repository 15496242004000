/**
 * <progress-bar params="progress:5"></progress-bar>
 * */
ko.components.register('paginate', {
    viewModel: function (params) {
        var self = this;
        self.module = params.module;
        self.template = params.template;
        self.buttonText = params.buttonText ? params.buttonText : 'Show more';
        self.loadMessage = params.loadMessage ? params.loadMessage : 'Loading...';
        self.emptyMessage = params.emptyMessage ? params.emptyMessage : 'No results';
        self.resultMessage = params.resultMessage ? params.resultMessage : null;

    },
    template: '<!-- ko if:module.data().length <=0 && emptyMessage && !module.loading()-->\n' +
        '        <div class="col-xs-12">\n' +
        '            <p class="pd-b-md" data-bind="text:emptyMessage"></p>\n' +
        '        </div>\n' +
        '<!-- /ko -->\n' +
        '\n' +
        '<!-- ko if:module.data().length >0 && resultMessage && !module.loading()-->\n' +
        '<div class="col-xs-12">\n' +
        '    <p class="pd-b-md" data-bind="text:resultMessage"></p>\n' +
        '</div>\n' +
        '<!-- /ko -->\n' +
        '\n' +
        '<div>\n' +
        '    <p><!-- ko template: { nodes: $componentTemplateNodes, data: $data } --><!-- /ko --></p>\n' +
        '</div>\n' +
        '\n' +
        '<!-- ko if:module.data().length >0 -->\n' +
        '<div class="col-xs-12" data-bind="template: {name:template,foreach: module.data}"></div>\n' +
        '<!-- /ko -->\n' +
        '\n' +
        '<div class="col-xs-12" data-bind="visible: module.loading">\n' +
        '    <div id="gusty-spinner">\n' +
        '        <div id="gusty-loader"></div>\n' +
        '    </div>\n' +
        '    <div class="text-primary text-center text-sm w500"><span data-bind="text:loadMessage"></span></div>\n' +
        '</div>\n' +
        '\n' +
        '<div style="display: none;" class="col-xs-12 text-center mg-t-sm mg-b-sm"\n' +
        '     data-bind="if:module.data().length >0 && !module.loading(),visible:module.canGoNext">\n' +
        '    <a href="#"\n' +
        '       aria-label="Successive"\n' +
        '       class="btn btn-success btn-sm"\n' +
        '       data-bind="click: module.loadMore,css: { disabled: !module.canGoNext() }"><span\n' +
        '        data-bind="text:buttonText"></span></a>\n' +
        '</div>'
});


