/**
 * Credits & Reference: http://c-snippets.com/Home/Details/243
 *
 * Example: <input name="image" type="file" data-bind="fileUpload: {property: 'imageFile', url: '/api/v1/offer/0/image'}">
 *     -    imageFile is an observable
 *     -    html name attribute is mandatory and it is POSTed
 *     -    this binding requires jquery.form.js
 *
 * @type {{init: init, update: update}}
 */

ko.bindingHandlers.fileUpload = {
    init: function (element, valueAccessor) {
        $(element).after('<div class="progress progress-striped mg-t-md active" style="display: none">' +
          '<div class="progress-bar progress-bar-info"><span>0%</span></div>' +
          '</div>' +
          '<div class="progressError text-danger text-sm" style="display: none"></div>')
    },
    update: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        var options = ko.utils.unwrapObservable(valueAccessor()),
            property = valueAccessor().property,
            callback = valueAccessor().callback,
            beforeUpload = valueAccessor().beforeUpload,
            url = ko.utils.unwrapObservable(options.url);

        if (url) {
            $(element).change(function () {
                if (element.files.length) {
                    var $this = $(this),
                        fileName = $this.val();

                    // this uses jquery.form.js plugin
                    $(element.form).ajaxSubmit({
                        url: url,
                        type: "POST",
                        dataType: "json",
                        headers: {"Content-Disposition": "attachment; filename=" + fileName},
                        beforeSubmit: function () {
                            $(element).next('.progress').show();
                            $(element).nextAll(".progressError").hide();
                            $(element).next('.progress').find('.progress-bar-info').width("0%");
                            $(element).next('.progress').find('.progress-bar span').html("0%");
                            if (beforeUpload) {
                                beforeUpload();
                            }
                        },
                        uploadProgress: function (event, position, total, percentComplete) {
                            var percentVal = percentComplete + "%";
                            $(element).next('.progress').find('.progress-bar-info').width(percentVal);
                            $(element).next('.progress').find('.progress-bar span').html(percentComplete < 100 ? percentVal : 'Wait...');
                        },
                        success: function (response) {
                            $(element).next('.progress').hide();
                            $(element).nextAll(".progressError").hide();
                            $(element).next('.progress').find('.progress-bar-info').width("0%");
                            $(element).next('.progress').find('.progress-bar span').html("0%");
                            // set viewModel property to filename
                            if (response.success && response.data)
                                if (Array.isArray(response.data)) {
                                    property(response.data);
                                } else {
                                    property(response.data.image);
                                }
                            else {
                                $this.val('')
                                $(element).nextAll('.progressError').html(response.message ? response.message : 'Error: You can use photos in jpg, gif, and png formats, with a minimum resolution of 400x400 and a maximum size of 5MB.')
                                $(element).nextAll('.progressError').show()
                            }
                            // Invoke callback.
                            if (callback) {
                                callback(response);
                            }
                        },
                        error: function (jqXHR, textStatus, errorThrown) {
                            $(element).next('.progress').hide();
                            var errors = jqXHR.responseJSON;
                            $(element).nextAll(".progressError").html(jqXHR.responseText);
                        }
                    });
                }
            });
        }
    }
};